@charset "UTF-8";
@import url("css/bootstrap.min.css");
@import url("css/animate.css");
@font-face {
  font-family: 'MullerRegular';
  src: url('fonts/MullerRegular.eot');
  src: url('fonts/MullerRegular.woff2') format('woff2'),
  url('fonts/MullerRegular.woff') format('woff'),
  url('fonts/MullerRegular.ttf') format('truetype'),
  url('fonts/MullerRegular.otf') format('opentype'),
  url('fonts/MullerRegular.svg#MullerRegular') format('svg'),
  url('fonts/MullerRegular.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'MullerLight';
  src: url('fonts/MullerLight.eot');
  src: url('fonts/MullerLight.woff2') format('woff2'),
  url('fonts/MullerLight.woff') format('woff'),
  url('fonts/MullerLight.ttf') format('truetype'),
  url('fonts/MullerLight.otf') format('opentype'),
  url('fonts/MullerLight.svg#MullerLight') format('svg'),
  url('fonts/MullerLight.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'MullerMedium';
  src: url('fonts/MullerMedium.eot');
  src: url('fonts/MullerMedium.woff2') format('woff2'),
  url('fonts/MullerMedium.woff') format('woff'),
  url('fonts/MullerMedium.ttf') format('truetype'),
  url('fonts/MullerMedium.svg#fonts/MullerMedium') format('svg'),
  url('fonts/MullerMedium.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MullerExtraBold';
  src: url('fonts/MullerExtraBold.eot');
  src: url('fonts/MullerExtraBold.woff2') format('woff2'),
  url('fonts/MullerExtraBold.woff') format('woff'),
  url('fonts/MullerExtraBold.ttf') format('truetype'),
  url('fonts/MullerExtraBold.otf') format('opentype'),
  url('fonts/MullerExtraBold.svg#MullerExtraBold') format('svg'),
  url('fonts/MullerExtraBold.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'MullerBold';
  src: url('fonts/MullerBold.eot');
  src: url('fonts/MullerBold.woff2') format('woff2'),
  url('fonts/MullerBold.woff') format('woff'),
  url('fonts/MullerBold.ttf') format('truetype'),
  url('fonts/MullerBold.otf') format('opentype'),
  url('fonts/MullerBold.svg#MullerBold') format('svg'),
  url('fonts/MullerBold.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

html,
body,
ul {
  margin: 0;
  padding: 0; }

body {
  font-family: 'MullerRegular', sans-serif;
}

section:not(.fs-section),
.brands {
  padding: 90px 0;
}

img {
  max-width: 100%; }

ul {
  list-style: none; }

a,
button {
  transition: all .4s;
  cursor: pointer; }

button:focus {
  outline: none; }

a:hover {
  text-decoration: none; }
$orange: #d99d61;
@mixin btn {
  display: block;
  position: relative;
  outline: none;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  font-weight: 700;
  letter-spacing: .05em;


  span {
    position: relative;
    z-index: 1;
    display: block;

    padding: 20px 15px;
    font-size: 24px;
    text-align: center;
    font-family: 'MullerMedium', sans-serif;
    border: none;
    border-radius: 50px;
    background-image: linear-gradient(to bottom, #f5e49f, #bea348);

    box-shadow: 4px 4px 15px 1px rgba(200, 175, 89, .2);
    transition: transform .1s, box-shadow .2s, background-color .2s;
  }
  &::after {
    content: '';
    position: absolute;
    top: -1em;
    bottom: -1em;
    left: -1em;
    right: -1em;
    border-radius: 4em;
    box-shadow: 0 0 0px rgba(0,0,0,0.2), inset 0 0 0px rgba(0,0,0,0.2);
    transition: box-shadow .1s;
  }

  &:hover {
    span {
      background-image: linear-gradient(to bottom, #bea348, #f5e49f);
    }
  }
  &:active {
    span {
      background-color: $orange;
      transform: scale(0.97);
      box-shadow: -2px 2px 5px rgba(0,0,0,0.2);
    }
    &::after {
      box-shadow: 0 0 2px rgba(0,0,0,0.2), inset -3px 3px 1em rgba(0,0,0,0.2);
    }
  }
}


.fs {
  background: url(img/bg1.png) center top / cover no-repeat;
}
.page-header {
  padding-top: 30px;
}
.fs-section {
  padding-bottom: 100px;
}
.header-inner,
.main-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nav-item {
  position: relative;
}
.menu-link {
  padding: 0;
  margin-right: 80px;
  font-family: 'MullerLight', sans-serif;
  font-size: 18px;
  color: #fff;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    color: #ddc879;

    & + .submenu {
      display: block;
    }
  }
}
.submenu {
  display: none;
  position: absolute;
  z-index: 5;
  left: 8px;
  top: 100%;
  width: 230px;
  color: #000;
  background-color: #fff;
  padding: 15px;
}
.phone {
  display: block;
  font-family: 'MullerBold', sans-serif;
  font-size: 18px;
  color: #fff;
  text-align: right;

  &:hover {
    color: #ddc879;
  }
}
.text-block {
  margin-top: 60px;
}
.main-header {
  margin-bottom: 60px;
  font-family: 'MullerBold', sans-serif;
  font-size: 52px;
  color: #fff;
  text-transform: uppercase;

}
.main-subheader {
  position: relative;
  padding-left: 80px;
  margin-bottom: 60px;
  font-size: 22px;
  color: #fff;
  line-height: 1.4;

  & span {
    color: #bea348;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(#bea348, #f5e49f, #d5b659);
    background-image: -ms-linear-gradient(transparent, transparent);
  }

  &::before {
    content: '';
    display: block;
    width: 65px;
    height: 2px;
    position: absolute;
    left: 0;
    top: 10px;
    background-image: linear-gradient(#bea348, #f5e49f, #d5b659);

  }
}
.fs-advantages {
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;
  max-width: 500px;
}
.adv-num {
  font-family: 'MullerExtraBold', sans-serif;
  font-size: 18px;
  color: #cab15a;
  line-height: 1;
  & span {
    margin-right: 5px;
    font-size: 55px;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(to bottom, #bea348, #f5e49f);
    background-image: -ms-linear-gradient(transparent, transparent);
  }
}
.advant-text {
  color: #fff;
  font-size: 18px;
}
.call-btn {
  @include btn;
}
.fs-btn {
  width: 315px;
}
.section-header {
  margin-bottom: 60px;
  font-size: 32px;
  font-family: 'MullerBold', sans-serif;
  text-align: center;
  text-transform: uppercase;
}
.basis {
  display: flex;
  flex-direction: column;
  padding: 40px 20px;
  height: 100%;
  background-color: #fff;
  box-shadow: 0 0 40px 1px rgba(0,0,0,.1);
}
.basis-img {
  display: block;
  height: 70px;
  margin: 0 auto 30px;
}
.basis-header {
  display: table;
  margin: 0 auto 20px;
  font-family: 'MullerBold', sans-serif;
  font-size: 20px;
  line-height: 1;
  text-transform: uppercase;

  &::after {
    content: '';
    display: block;
    height: 3px;
    width: 115px;
    margin: 15px auto 0;
    background-image: linear-gradient(to bottom, #bea348, #f5e49f);
  }
}
.basis-text {
  text-align: center;
  font-family: 'MullerLight', sans-serif;
  font-size: 18px;
}
.brands {
  background: url(img/bg2.png) left top / contain no-repeat #2d2d30;
}
.brands-wrapper {
  padding: 0 10px;
}
.brand-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }
}
.big-brands .brand-img {
  max-height: 50px;
}
.brand-img-wrapper {

}
.brand-img {
  max-width: 33.3%;
  max-height: 75px;
  transition: all .4s;
  cursor: pointer;

  &:hover {
    filter: contrast(150%);
  }
}
.service {
  margin-bottom: 70px;
  cursor: pointer;
}
.service-img {
  display: block;
  margin: 0 auto 10px;
  height: 230px;
  transition: all .4s;

  &:hover {
    filter: sepia(100%);
  }
}
.service-header {
  margin-left: 25px;
  font-family: 'MullerBold', sans-serif;
  text-transform: uppercase;
}
.service-p {
  margin-bottom: 70px;
  font-style: italic;
  font-family: 'MullerLight', sans-serif;
  text-align: center;
  & span {
    font-family: 'Muller Regular', sans-serif;
    font-weight: 600;

  }
}
.consult-btn {
  width: 420px;
  margin: auto;
}
.why-section {
  background: url(img/bg3.png) right top / 50% no-repeat #2d2d30;
}
.why-section-header {
  text-align: left;
  color: #fff;
  text-transform: uppercase;
  line-height: 1.3;

  & span span {
    color: #b99e4a;
  }
}
.reason-slider {
  padding: 0;
}
.reason-header {
  position: relative;
  margin-bottom: 50px;
  font-family: 'MullerBold', sans-serif;
  font-size: 30px;
  color: #fff;
}
.reason {
  margin-bottom: 120px;
  display: flex;
  align-items: center;

  &.reason-left {
    .reason-info {
      margin-left: 50px;

    }
    .reason-header {
      text-align: left;
    }
    .reason-text {
      padding-left: 50px;
    }
  }
  &.reason-right {
    .reason-info {
      margin-right: 50px;

    }
    .reason-header {
      text-align: right;
    }
    .reason-text {
      padding-right: 50px;
    }
  }
}
.reason-img {
  max-width: 50%;
}
.reason-info {

}
.reason-text {

  color: #bbbaba;
  font-family: 'MullerMedium', sans-serif;

}
.first-reason {
  background: url(img/reas1.png) left top no-repeat;
}
.second-reason {
  background: url(img/reas2.png) right top no-repeat;
}
.third-reason {
  background: url(img/reas3.png) left top no-repeat;
}
.fourth-reason {
  background: url(img/reas4.png) right top no-repeat;
}
.slick-slide {
  outline: none;
}
.stories-slider-wrapper {
  position: relative;
}

.stories-slide,
.slide-subslides {
  display: flex;
  justify-content: space-between;
}
.slide-subslides {
  align-items: center;
}
.stories-slide {
  padding-bottom: 20px;
}
.slider-left-wrapper,
.diagnosis {
  width: 50%;
}
.slider-count {
  position: absolute;
  right: 55px;
  .current {
    font-family: 'MullerMedium', sans-serif;
  }
  .total {
    font-family: 'MullerLight', sans-serif;
  }
}
.slide-img-wrapper {
  margin-bottom: 15px;
}
.slide-img-small {
  width: 31%;
}
.diagnosis {
  position: relative;
  padding-left: 80px;
  padding-right: 55px;
}
.model-header,
.model-name {
  font-family: 'MullerMedium', sans-serif;
  font-size: 25px;
}
.model-name {
  margin-bottom: 30px;
  color: #202020;
}
.model-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.model-info-item {
  padding-left: 55px;

  &:first-child {
    background: url(img/purse.png) left center no-repeat;
  }
  &:last-child {
    background: url(img/guarantee.png) left center no-repeat;
  }
}
.model-info-header {
  margin-bottom: 5px;
}
.model-info-text {
  font-family: 'MullerMedium', sans-serif;
  font-size: 20px;
}
.features-header {
  margin-bottom: 5px;
  font-size: 20px;
  font-family: 'MullerMedium', sans-serif;
}
.features-text {
  margin-bottom: 20px;
  font-family: 'MullerLight', sans-serif;
}
.slider-btn {
  width: 450px;

  & span {
    font-size: 18px;
  }
}
.next-arrow {
  width: 33px;
  height: 24px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background: url(img/right-arrow.png) center no-repeat;
  border: none;
}
section.more-section {
  padding-bottom: 0;
  background: url(img/bg4.png) right top no-repeat #2d2d30;
}
.form-wrapper {
  margin-top: 80px;
  padding: 50px 40px;
  text-align: center;
  border-radius: 15px;
  background: url(img/form-bg.png) left top no-repeat #f9f7f3;
}
.form-text {
  margin-bottom: 55px;
  font-size: 20px;
  font-style: italic;
}
.form-call {
  margin-bottom: 55px;
  font-family: 'MullerLight', sans-serif;
  font-size: 15px;
}
.form-input-wrapper {
  padding: 20px 15px;
  border-radius: 50px;
  background: url(img/phone.png) right 35px center no-repeat #e6e6e6;
  box-shadow: inset 1px 1px 10px rgba(0,0,0,.1);

}
.form-input {
  background-color: transparent;
  border: none;
  outline: none;
}
::-webkit-input-placeholder {
  font-family: 'MullerLight', sans-serif;
  color: #7c7c7c;
}
::-moz-placeholder          {
  font-family: 'MullerLight', sans-serif;
  color: #7c7c7c;
}
:-ms-input-placeholder      {
  font-family: 'MullerLight', sans-serif;
  color: #7c7c7c;
}
.form-btn {
  margin-top: 15px;

  width: 100%;

  & span {
    font-size: 18px;
  }
}
.more-header {
  margin-bottom: 15px;
  text-align: left;
  color: #fff;
}
.more-text {
  padding-left: 70px;
  padding-top: 30px;
  background: url(img/arrow.png) left top no-repeat;
}
.more-text {
  font-family: 'MullerLight', sans-serif;
  font-size: 18px;
  color: #fff;
}
.box-img {
  position: relative;
  top: 50px;
  left: -50px;
}
.contact-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.contacts-header {
  text-align: left;
}
.contact-item {
  display: flex;
  align-items: center;
}
.contact-img {
  margin-right: 45px;
}
.contacts-phones span {
  display: block;
  line-height: 1em;
    font-size: 20px;
}
.contacts-phone,
.contacts-address,
.contacts-schedule {

  font-size: 20px;
  color: #000;
}

.contacts-phone{
  display: block;


  &:hover {

    color: #ddc879;
  }
}
.page-footer {
  display: none;
  padding: 25px 0;
}
.footer-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.pricacy-link{
  color: #000;
  font-size: 15px;
}
@media screen and (max-width: 1199px) {
  .first-basis::before {
    left: -20px;
  }
  .third-basis::before {
    right: -20px;
  }
  .first-basis::before,
  .third-basis::before {
    width: 105px;
  }
  .service-img {
    height: 200px;
  }
  .reason-header {
    position: static;
    font-size: 22px;
  }
  .slider-btn {
    width: 100%;
  }
  .diagnosis {
    padding-left: 30px;
    padding-right: 30px;
  }
  .menu-link {
    margin-right: 40px;
  }

}
@media screen and (max-width: 991px) {
  .basis {
    height: auto;
    margin-bottom: 20px;
  }
  .first-basis,
  .third-basis {
    align-items: center;
    border-radius: 0;
    &::before {
      display: none;
    }
    & .basis-inner {
      max-width: 100%;
    }
  }
  .service-header {
    text-align: center;
    margin-left: 0;
  }
  .reason {
    display: flex!important;
    flex-direction: column;

    &.reason-left,
    &.reason-right {

      .reason-header {
        text-align: center;
      }

      .reason-text {
        padding: 0;
        margin-bottom: 40px;
        text-align: center;
        background: none;
      }
      .reason-info {
        margin: 0;
      }
    }
  }
  .reason-header {
    text-align: center;
  }
  .reason-img {
    order: 2;
  }
  .reason-img {
    max-width: 100%;
  }
  .slick-dots {
    bottom: 0;
    width: 100%;
  }
  .slick-dots li {
    margin: 0 5px;
    width: auto;
    height: auto;
    transition: all .4s;
    button {
      width: auto;
      height: auto;
    }
    &.slick-active {
      transform: translateY(-20px);
      button {
        color: #b99e4a;

      }


    }

    button {
      transform: rotate(-90deg);
      font-size: 20px;
      line-height: 1;
      color: #686868;
      font-family: 'MullerMedium', sans-serif;

      &:before {
        display: none;
      }
    }
  }
  .section-header {
    text-align: center;
  }
  .stories-slide {
    width: 75%;
    margin: auto;
    flex-direction: column;
  }
  .diagnosis,
  .slider-left-wrapper {
    width: 100%;
    margin: auto;
  }
  .diagnosis {
    margin-top: 40px;
    padding: 0;
  }
  .slide-img {
    width: 100%;
  }
  .next-arrow {
    top: auto;
    right: 100px;
    bottom: 150px;
  }
  .features-text {
    max-width: 75%;
  }
  .main-menu {
    display: none;
  }
  .more-col {
    order: -1;
  }
  .box-img {
    display: none;
  }
  section.more-section {
    padding-bottom: 90px;
  }
  .contact-wrapper {
    max-width: 50%;
    height: auto;
    margin: 0 auto 50px;
  }
  .contact-item {
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }
  }
  .more-text {
    display: table;
    margin: 0 auto;
    padding-left: 70px;
  }
}
@media screen and (max-width: 767px) {
  .main-header {
    text-align: center;
    font-size: 40px;
  }
  .main-subheader {
    padding-left: 0;
    text-align: center;
    &::before {
      display: none;
    }
  }
  .fs-advantages {
    max-width: 100%;
  }
  .fs-btn {
    margin: auto;
  }
  .stories-slide {
    width: 100%;
  }
  .next-arrow {
    right: 0;
  }
  .slider-btn {
    width: 400px;
    margin: auto;
  }
  .contact-wrapper {
    max-width: 70%;
  }
  .brands-wrapper {
    display: block;
    max-width: 50%;
    margin-left: auto;

  }
  .call-btn {
    &::after {
      top: -10px;
      bottom: -10px;
      left: -10px;
      right: -10px;
    }

  }
}
@media screen and (max-width: 575px) {
  section.map-section {
    padding-bottom: 0;
  }
  .page-footer {
    display: block;
  }
  .section-header {
    font-size: 24px;
  }
  .form-wrapper {
    max-width: 75%;
    margin: 50px auto 0;
  }
  .brands {
    background: url(img/bg2-mob.png) left top/contain no-repeat #2d2d30;
  }
  section.more-section {
    background: url(img/bg4-mob.png) right top no-repeat #2d2d30;
  }
  .reason-img {
    max-width: 95%;
  }
  .main-header {
    font-size: 35px;
  }
  .basis {
    max-width: 75%;
    margin: 0 auto 20px;
  }
  .contact-wrapper {
    max-width: 75%;
  }
}
@media screen and (max-width: 480px) {
  .fs {
    background: url(img/bg1-mob.png) center top no-repeat;
  }
  .service-img {
    height: 150px;
  }
  .logo {
    max-width: 180px;
  }
  .adv-num span {
    font-size: 45px;
  }
  .brands-wrapper {
    max-width: 65%;

  }
  .section-header {
    font-size: 22px;

    & br {
      display: none;
    }
  }
  .why-section-header > span {
    display: block;
  }
  .form-wrapper {
    max-width: 90%;
    padding: 40px 20px;
  }
  .more-text br:last-child {
    display: none;
  }
  .service-p,
  .consult-btn {
    display: none;
  }
  section:not(.fs-section):not(.map-section),
  .brands {
    padding: 50px 0;
  }
  .reason {
    margin-bottom: 70px;
  }
  .slider-btn {
    width: 320px;

    span {
      font-size: 15px;
    }
  }
  .main-header {
    font-size: 30px;
  }
  .adv-num {
    font-size: 16px;

    & span {
      margin-right: 0;
      font-size: 40px;
    }
  }
  .logo {
    max-width: 150px;
  }
  .call-btn {
    span {
      font-size: 18px;
      padding: 15px;
    }

  }
  .slider-btn span {
    font-size: 15px;
  }
  .service-header {
    font-size: 12px;
  }
  .contact-wrapper {
    max-width: 85%;
  }
  .contact-img {
    margin-right: 25px;
  }
  .contacts-phones span,
  .contacts-phone, .contacts-address, .contacts-schedule {
    font-size: 18px;
  }
  .model-info-item {
    padding-left: 35px;
    background-size: 25px!important;
  }
  .model-info-header {
    font-size: 14px;
  }
  .model-info-text {
    font-size: 16px;
  }
  .features-text {
    max-width: 85%;
  }
  .more-text {
    margin-left: 20px;
  }
  .slider-count {
    right: 0;
  }
}
@media screen and (max-width: 380px) {
  .section-header {
    margin-bottom: 20px;
    font-size: 17px;
  }

  .basis {
    max-width: 100%;
  }
  .service-img {
    height: 120px;
  }
  .phone {
    font-size: 14px;
  }
  .reason-header {
    margin-bottom: 15px;
    font-size: 18px;
  }
  .main-header {
    font-size: 25px;
  }


  .call-btn {

    span {
      font-size: 16px;
    }

  }
  .slider-btn {
    width: 90%;

    & span {
      font-size: 14px;
    }
  }
  .form-wrapper {
    max-width: 100%;
  }
  .contact-item {
    margin-bottom: 20px;
  }
  .contact-wrapper {
    max-width: 70%;
  }
  .contacts-phones span,
  .contacts-phone, .contacts-address, .contacts-schedule {
    font-size: 14px;
  }
  .contact-img {
    width: 25px;
    margin-right: 15px;
  }
  .adv-num {
    font-size: 14px;

    & span {
      font-size: 30px;
    }
  }
  .advant-text {
    font-size: 14px;
  }
  .fs-btn {
    width: 260px;
  }
  .main-header {
    margin-bottom: 15px;
  }
  .model-name {
    margin-bottom: 15px;
  }
  .features-text {
    width: 80%;
  }
  .form-input-wrapper {
    padding: 15px 10px;
  }
  .call-btn span {
    padding: 15px 10px;
  }
  .more-text {
    font-size: 14px;
  }
  .form-text {
    margin-bottom: 40px;
    font-size: 18px;
  }
  .form-call {
    margin-bottom: 40px;
  }
  .basis-text {
    font-size: 16px;
  }
}